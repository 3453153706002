jQuery(document).ready(function( $ ) {

  $('.pc-mobile-open').on('click',function(e){
    e.preventDefault();
    $('header.pc-header-top').addClass('open');
    $('body').addClass('pc-frozen');

    $("header.pc-header-top ul.menu li").each(function(i,something) {
      setTimeout(function() {
        $(something).addClass("show");
      },100*i);
    });
  });

  $('.pc-mobile-close').on('click',function(e){
    e.preventDefault();
    $('header.pc-header-top').removeClass('open');
    $('body').removeClass('pc-frozen');
    $("header.pc-header-top ul.menu li").removeClass('show');
  });


});
